
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import ThirdPartyForm from "@/components/reusable/claims/house-insurance/ThirdPartyForm.vue";
import ComprehensiveThirdPartyForm from "@/components/reusable/claims/house-insurance/ComprehensiveThirdPartyForm.vue";
import ClaimNatures from "@/views/reusable/claims/motor-insurance/claim-natures.json";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "motor-insurance-claims-edit-component",
  components: {
    Form,
    Field,
    ErrorMessage,
    ImagesUploader,
    ThirdPartyForm,
    ComprehensiveThirdPartyForm,
    GoBackButton,
  },
  props: { publicId: String },
  data() {
    return {
      role: "",
      route: "",
      policyItemRoute: "",
      icClass: "col-xl-12",
      ttgClass: "col-xl-4",
      hprClass: "col-xl-6",
      hasFireBrigadeReport: false,
      hasPoliceReport: false,
      hasBeenTakenToGarage: false,
      hasLicence: false,
      hasThirdPartyInfo: false,
      hasGarageInfo: false,
      doesNotHaveGarageInfo: false,
      isALicensedDriver: false,
      // publicId: this.$route.params.publicId,
      claimNatures: ClaimNatures,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      minimumDate: "",
      todaysDate: "",
      locationOfIncident: "",
      policeReport: "",
      takenToGarage: "",
      licensedDriver: "",
      weatherCondition: "",
      accidentImage: "",
      descriptionOfIncident: "",
      acceptTerms: false,
      garageName: "",
      garagePhoneNumber: "",
      amount: "",
      amountImage: "",
      garageAddress: "",
      damagedVehicleImages: "",
      policy: "",
      policies: [{} as any],
      policyDetails: [{} as any],
      currentTime: variables.currentTime(),
      isNotThirdParty: false,
      isThirdParty: false,
      areYouInjured: "",
      displayInjuryFields: false,
      medicalReport: "",
      medicalBill: "",
      medicalBillValue: "",
      bodilyInjuriesImages: "",
      notifyFireBrigade: "",
      notifyPolice: "",
      isFireDamaged: { field: false, class: "col-xl-12" },
      isVandalized: { field: false, class: "col-xl-12" },
      isLoneAccident: { field: false, class: "col-xl-6" },
      fireBrigadeReport: "",
      hasDriverLicense: "",
      myDriverLicense: "",
      wereYouAtFault: "",
      apprehendThirdParty: "",
      inspectionLocation: "",
      fields: {
        estimateOfRepair: {
          primaryField: false,
          primaryClass: "col-xl-6",
          uploadField: false,
          uploadClass: "col-xl-6",
        },
        areYouAtFault: {
          field: false,
          class: "col-xl-12",
        },
        apprehendThirdParty: {
          field: false,
          class: "col-xl-6",
        },
        atFaultNature: { field: false, class: "col-xl-4" },
        thirdPartyDriverLicense: { field: false, class: "col-xl-4" },
        displayComprehensiveThirdPartyForm: false,
        displayNoClaimProcess: false,
      },
      claimDetails: {} as any,
    };
  },
  setup(props) {
    const { findObjectByKeyValue } = ResusableFunctions();

    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed()
        .required()
        .label("Policy reference"),
      itemPublicId: Yup.mixed()
        .required()
        .label("Accidented vehicle"),
      claimNature: Yup.mixed()
        .required()
        .label("Accidented vehicle"),
      lossDate: Yup.date()
        .required()
        .label("Date and time of incident"),
      locationOfIncident: Yup.string()
        .required()
        .label("Location of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      // damagedVehicleImages: Yup.mixed()
      //   .required()
      //   .label("Upload damaged vehicles"),
      // garagePhoneNumber: Yup.number()
      //   .min(11)
      //   .required()
      //   .label("Garage phone number"),
      // acceptTerms: Yup.boolean()
      //   .oneOf([true])
      //   .required()
      //   .label("Terms & conditions"),
    });

    const submitForm = (values, { resetForm }) => {
      console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (const [key, value] of Object.entries(values)) {
              if (Array.isArray(value)) {
                value.forEach((itemValue, index) => {
                  formData.append(
                    `${key}[${index}]`,
                    itemValue as string | Blob
                  );
                });
              } else {
                formData.append(key, value as string | Blob);
              }
            }

            ApiService.setHeader();
            ApiService.post(
              `${variables.CLAIMS_ROUTE}/${props.publicId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Claims",
        "Report Claims",
        "Motor Insurance Claim",
      ]);
    });

    return { submitButton1, claimsValidator, submitForm, findObjectByKeyValue };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.role = store.getters.currentUser.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get claim information route based on user roles
    if (this.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.POLICIES_ROUTE}`;
    } else if (this.role == variables.BROKER_USER_ROLE) {
      // Broker role claim information route
      // this.route = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`;
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}`;
    } else if (
      this.role == variables.ADMIN_USER_ROLE ||
      this.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role claim information route
      this.route = `${variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}`;
    }

    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    // Get the associated claim information
    this.getClaimInformation();
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.claimDetails = data.data;
            console.log(this.claimDetails);
            this.getPolicyInformation(data?.data?.metadata?.policy);
            this.claimNature = data.data.metadata.claimNature;
            this.policy = data.data.metadata.policy;
            this.itemPublicId = data.data.metadata.itemPublicId;
            this.lossDate = data.data.metadata.lossDate;
            this.amount = data.data.metadata.amount;
            this.locationOfIncident = data.data.metadata.locationOfIncident;
            this.licensedDriver = data.data.metadata.licensedDriver;
            this.weatherCondition = data.data.metadata.weatherCondition;
            this.descriptionOfIncident =
              data.data.metadata.descriptionOfIncident;
            this.areYouInjured = data?.data?.metadata?.areYouInjured;
            this.wereYouAtFault = data?.data?.metadata?.wereYouAtFault;
            this.takenToGarage = data?.data?.metadata?.takenToGarage;
            this.medicalBillValue = data?.data?.metadata?.medicalBillValue;
            this.garageAddress = data?.data?.metadata?.garageAddress;
            this.garageName = data?.data?.metadata?.garageName;
            this.garagePhoneNumber = data?.data?.metadata?.garagePhoneNumber;
            this.apprehendThirdParty =
              data?.data?.metadata?.apprehendThirdParty;

            this.displayInjuryFields = this.areYouInjured === "Yes";

            if (this.wereYouAtFault && this.wereYouAtFault === "Yes") {
              this.fields.areYouAtFault.field = true;
              this.fields.areYouAtFault.class = "col-xl-12";
              this.fields.atFaultNature.field = true;
            } else {
              this.fields.apprehendThirdParty.field = true;
            }

            if (this.takenToGarage && this.takenToGarage === "Yes") {
              this.hasGarageInfo = true;
              this.doesNotHaveGarageInfo = false;
            } else {
              this.hasGarageInfo = false;
              this.doesNotHaveGarageInfo = true;
            }

            if (
              this.apprehendThirdParty &&
              this.apprehendThirdParty === "Yes"
            ) {
              this.fields.displayComprehensiveThirdPartyForm = true;
            } else {
              this.fields.displayComprehensiveThirdPartyForm = false;
            }

            // this.thirdPartyFullName = data.data.metadata.thirdPartyFullName;
            // this.thirdPartyPhoneNumber =
            //   data.data.metadata.thirdPartyPhoneNumber;
            // this.thirdPartyEyeWitness = data.data.metadata.thirdPartyEyeWitness;
            // this.thirdPartyDriverLicense =
            //   data.data.metadata.thirdPartyDriverLicense;
            // this.thirdPartyInsuranceCertificate =
            //   data.data.metadata.thirdPartyInsuranceCertificate;
            // this.thirdPartyOtherDocuments =
            // data.data.metadata.thirdPartyOtherDocuments;
            // this.garageAddress = data.data.metadata.garageAddress;
            // this.garagePhoneNumber = data.data.metadata.garagePhoneNumber;
            // this.takenToGarage = data.data.metadata.takenToGarage;
            // this.policyStartDate = data.data.policyStartDate;
            // policeReport: "",
            // accidentImage: "",
            // amountImage: "",
            // damagedVehicleImages: "",
            // if (data.data.lossType == "Accident") {
            //   this.hasLicence = true;
            //   this.hasGarageInfo = true;
            //   this.hasBeenTakenToGarage = true;
            // } else if (data.data.lossType == "Fire Damage") {
            //   this.hasBeenTakenToGarage = true;
            //   this.hasGarageInfo = true;
            //   this.icClass = "col-xl-12";
            //   this.ttgClass = "col-xl-12";
            // } else if (data.data.lossType == "Third-Party Incident") {
            //   this.hasPoliceReport = true;
            //   this.hasThirdPartyInfo = true;
            //   this.hasLicence = true;
            // } else if (data.data.lossType == "Stolen(Theft)") {
            //   this.hasPoliceReport = true;
            //   this.icClass = "col-xl-12";
            //   this.hprClass = "col-xl-12";
            // } else if (data.data.lossType == "Vandalised") {
            //   this.hasPoliceReport = true;
            //   this.hasBeenTakenToGarage = true;
            //   this.hasGarageInfo = true;
            //   this.hprClass = "col-xl-6";
            //   this.ttgClass = "col-xl-6";
            // } else {
            //   this.defaultValues();
            // }
          })
          .catch(function(error) {
            variables.toastAlert(error?.response?.data?.error, "error");
            console.log(error);
            // router.go(-1);
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/business-class/MOT`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.policyItemRoute}/${value}/items`)
          .then(({ data }) => {
            //Assign data to variables
            this.policyDetails = data.data;
            if (this.policyDetails) {
              this.determinePolicyType(this.claimDetails?.policyType);

              setTimeout(() => {
                const code = this.findObjectByKeyValue(
                  this.claimNatures,
                  "name",
                  this.claimNature
                );

                if (code?.code) {
                  this.determineClaimNature(code?.code);
                }
              }, 2000);
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getClaimNature(event) {
      this.defaultValues();

      const code =
        event.target.options[event.target.selectedIndex].dataset.code;
      this.determineClaimNature(code);
    },
    determineClaimNature(code) {
      // Reset fields
      // this.fields.areYouAtFault.field = false;
      // this.fields.atFaultNature.field = false;
      // this.hasThirdPartyInfo = false;
      // this.fields.estimateOfRepair.primaryField = true;
      // this.fields.estimateOfRepair.uploadField = true;

      // If the claim nature is accident
      if (code == "AC") {
        this.isLoneAccident.field = true;
        this.isFireDamaged.field = false;
        this.isVandalized.field = false;
        this.hasLicence = false;
        this.hasThirdPartyInfo = false;
        this.hasPoliceReport = false;
        this.hasBeenTakenToGarage = true;
      } else if (code == "FD") {
        this.isFireDamaged.field = true;
        this.isVandalized.field = false;
        this.isLoneAccident.field = false;
        this.hasThirdPartyInfo = false;
        this.hasLicence = false;
        this.hasPoliceReport = false;
      } else if (code == "FSD") {
        this.isFireDamaged.field = false;
        this.isVandalized.field = false;
        this.isLoneAccident.field = false;
        this.hasThirdPartyInfo = false;

        this.hasLicence = false;
      } else if (code == "TPI") {
        // this.hasThirdPartyInfo = true;
        this.hasPoliceReport = false;
        this.isLoneAccident.field = false;
        this.isFireDamaged.field = false;
        this.isVandalized.field = false;
        this.hasLicence = false;
        this.fields.areYouAtFault.field = true;
        this.icClass = "col-xl-8";
      } else if (code == "ST") {
        this.hasPoliceReport = true;
        this.icClass = "col-xl-12";
        this.hprClass = "col-xl-12";

        this.isVandalized.field = false;
        this.isFireDamaged.field = false;
        this.isLoneAccident.field = false;
        this.hasThirdPartyInfo = false;
        this.hasLicence = false;
      } else if (code == "VD") {
        this.isVandalized.field = true;
        this.isFireDamaged.field = false;
        this.isLoneAccident.field = false;
        this.hasThirdPartyInfo = false;
        this.hasLicence = false;

        // this.hasPoliceReport = true;
        // this.hasBeenTakenToGarage = true;
        // this.hprClass = "col-xl-6";
        // this.ttgClass = "col-xl-6";
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      const currentYear = new Date().getFullYear();
      this.minimumDate = currentYear + "-01-01";
      this.todaysDate = variables.currentDateTime();
    },
    defaultValues() {
      this.icClass = "col-xl-12";
      this.ttgClass = "col-xl-4";
      this.hprClass = "col-xl-6";

      this.hasPoliceReport = false;
      this.hasBeenTakenToGarage = false;
      this.hasLicence = false;
      this.hasThirdPartyInfo = false;
      // this.hasGarageInfo = false;
      this.isALicensedDriver = false;
      this.hasFireBrigadeReport = false;

      this.isVandalized.field = false;
      this.isFireDamaged.field = false;
      this.isLoneAccident.field = false;

      this.takenToGarage = "";
      this.licensedDriver = "";
      this.weatherCondition = "";
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // return variables.addCommaToNumber(amount);
    },
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
    checkTime(event) {
      const selectedTime = new Date(event.target.value).getTime();
      const maxTime = new Date(this.todaysDate).getTime();

      if (selectedTime > maxTime) {
        event.target.value = this.todaysDate;
        this.lossDate = event.target.value;
      }
    },
    getMotorPackage(event) {
      // Get the type mptor cover from the vehicle selected
      const type =
        event.target.options[event.target.selectedIndex].dataset.type;
      this.determinePolicyType(type);
    },
    determinePolicyType(type) {
      if (type != undefined) {
        if (type === "Motor Third-Party") {
          this.fields.areYouAtFault.field = true;
          this.isThirdParty = true;
          this.isNotThirdParty = false;
          this.icClass = "col-xl-8";
        } else {
          this.fields.areYouAtFault.field = false;
          this.isThirdParty = false;
          this.isNotThirdParty = true;
          this.icClass = "col-xl-12";
        }
      }
    },
    getTruthyChoice(event, field) {
      const value = event.target.options[event.target.selectedIndex].value;
      this[field] = value == "Yes" ? true : false;

      if (field !== "" && field === "hasFireBrigadeReport") {
        this.isFireDamaged.class = "col-xl-6";

        if (value === "No") {
          this.isFireDamaged.class = "col-xl-12";
        }
      }

      if (field !== "" && field === "hasPoliceReport") {
        this.isVandalized.class = "col-xl-6";

        if (value === "No") {
          this.isVandalized.class = "col-xl-12";
        }
      }

      if (field !== "" && field === "isALicensedDriver") {
        this.isLoneAccident.class = "col-xl-4";
        this.hasLicence = true;

        if (value === "No") {
          this.isLoneAccident.class = "col-xl-6";
          this.hasLicence = false;
        }
      }

      if (field !== "" && field === "areYouAtFault") {
        if (this.isNotThirdParty && value === "Yes") {
          this.fields.atFaultNature.field = true;
          this.fields.areYouAtFault.class = "col-xl-12";
          this.fields.apprehendThirdParty.field = false;
        }

        if (this.isNotThirdParty && value === "No") {
          this.fields.atFaultNature.field = false;
          this.fields.areYouAtFault.class = "col-xl-6";
          this.fields.apprehendThirdParty.field = true;
          this.icClass = "col-xl-12";
        }

        if (this.isThirdParty && value === "Yes") {
          this.fields.atFaultNature.field = true;
          // this.fields.thirdPartyDriverLicense.field = true;
          this.fields.displayNoClaimProcess = false;
          // this.fields.thirdPartyDriverLicense.class = "col-xl-6";
          // this.fields.areYouAtFault.class = "col-xl-4";
          this.icClass = "col-xl-8";
        }

        if (this.isThirdParty && value === "No") {
          this.fields.atFaultNature.field = false;
          this.fields.thirdPartyDriverLicense.field = false;
          this.fields.displayNoClaimProcess = true;
          this.fields.thirdPartyDriverLicense.class = "col-xl-4";
          // this.fields.areYouAtFault.class = "col-xl-4";
          this.icClass = "col-xl-8";
        }
      }

      if (field !== "" && field === "apprehendedThirdParty") {
        if (this.isNotThirdParty && value === "Yes") {
          this.fields.displayComprehensiveThirdPartyForm = true;
        }

        if (this.isNotThirdParty && value === "No") {
          this.fields.displayComprehensiveThirdPartyForm = false;
        }
      }

      if (field !== "" && field === "hasGarageInfo") {
        this.hasGarageInfo = true;
        this.doesNotHaveGarageInfo = false;

        if (value === "No") {
          this.doesNotHaveGarageInfo = true;
          this.hasGarageInfo = false;
        }
      }
    },
  },
});
